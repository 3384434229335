import BackendService from './BackendService';

export default class DROService {

    static fileStatus(status, key) {

        var body = {
            status: status,
            key: key
        }

        return BackendService.post("/dro/file/status", body).then(resp => {
            return resp.data
        })
    }

    static fileList(semester, year) {
        return BackendService.get("/dro/file/list?semester=" + semester + "&year=" + year).then(resp => {
            return resp.data
        })
    }

    static areaListTimeline(semester, year) {
        return BackendService.get("/dro/area/list/timeline?semester=" + semester + "&year=" + year).then(resp => {
            return resp.data
        })
    }

    static areaList() {
        return BackendService.get("/dro/area/list").then(resp => {
            return resp.data
        })
    }

    static registerUser(data) {
        return BackendService.post("/dro/user/role", data).then(resp => {
            return resp
        })
    }

    static deleteUser(email) {
        var body = {
            mail: email
        }

        return BackendService.delete("/dro/user/role", body).then(resp => {
            return resp
        })
    }

    static listRegisteredUsers() {
        return BackendService.get("/dro/user/role").then(resp => {
            return resp.data
        })
    }

    static fileRead(key) {
        const bucket = 'storm-portal'

        var body = {
            bucket: bucket,
            key: key
        }

        return BackendService.post("/dro/file/read", body, true).then(resp => {
            return resp
        })
    }

    static fileSend(data) {
        return BackendService.post("/dro/file/write", data).then(resp => {
            return resp
        })
    }

    static fileValidate(data) {
        return BackendService.post("/dro/file/validate", data).then(resp => {
            return resp
        })
    }

    static listAreasStatus() {
        return BackendService.get("/dro/area/list/status").then(resp => {
            return resp.data
        })
    }

    static sendMail(subject, to, body) {

        var email = {
            "body": body,
            "to": to,
            "subject": subject
        }

        return BackendService.post("/dro/mail", email).then(resp => {
            return resp
        })
    }
}
