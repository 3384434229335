<template>
  <!-- <Dialog v-model:visible="responseMsg" :showHeader="false" :dismissable-mask="false" modal
    :contentStyle="{ borderRadius: '5px', width: '20rem' }">
    <Card class="text-center">
      <template #title> Arquivo enviado com sucesso! </template>
      <template #content>
        <Button class="p-buttom p-component" label="OK!" @click="removeAttachment()" />
      </template>
    </Card>
  </Dialog> -->

  <Dialog :header="responseMsg.title" :closable="false" v-model:visible="dialogResponseMsg" style="width: 30vw;" :modal="true">
    <p style="margin-left: 20px;">{{ responseMsg.message }}</p>
    <template #footer>
      <Button label="OK" @click="removeAttachment()" autofocus />
    </template>
  </Dialog>

  <div class="grid">
    <Toast />
    <div class="col-12">
      <div class="card">
        <h5>Subir arquivo</h5>
        <div>
          <div v-if="hasNotSended" class="flex flex-row">
            <FileUpload name="demo[]" mode="basic" :customUpload="true" :auto="true" chooseLabel="Verificar Planilha"
              :maxFileSize="100000000" @uploader="myUploader" @select="selectAttachment" />
            <Button label="Template" icon="pi pi-download" style="margin-left: 30px" @click="downloadFile()" />
          </div>
          <div v-else-if="hasErrors">
            <Button class="p-buttom p-component p-button-danger" label="Reenviar" icon="pi pi-refresh"
              @click="removeAttachment()" />
          </div>
          <div v-else>
            <Button v-if="hasWarnings" class="p-buttom p-component p-button-warning" label="Enviar" icon="pi pi-upload"
              @click="sendToDatabase()" />
            <Button v-else class="p-buttom p-component p-button-success" label="Enviar" icon="pi pi-upload"
              @click="sendToDatabase()" />
            <Button class="p-buttom p-component p-button-danger" style="margin-left: 10px" icon="pi pi-times"
              @click="removeAttachment()" />
          </div>
        </div>
      </div>
      <div class="card" v-if="hasNotSended">
        <p><strong>Instruções de Preenchimento:</strong></p>
        <ol>
          <li>
            Baixar o template de preenchimento do demonstrativo de risco,
            disponibilizado no botão <span style="color: red">“TEMPLATE”</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHEAAAA1CAYAAAB2twSqAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAPiSURBVHhe7ZvNa+JAGMb3b92P7mlhb10Wr3uSgocWFg/FQ0vpUvZUCh42h0Kxh7VQL3rwYBfWIpaia9UYP56dN8nUqjNptNE0w/vAA0lmnEzeX+adUZM3YCVeDNEAMUQDxBANEEM0QAzRADFEA8QQDRBDNEBLQfxlDfDlawfvP7bxbou9TlOMKdYU8+cUGuLe9y4+ff7nnuDth9bCSdnRWsaYYk6xD1IoiHQ3SIDszZtiHzQiQ0GkYa1qnL05EwOdQkGk/MwpND5T7ImBTqEgqhpmb946McQEWSeGmCDrxBATZJ0YYoKsUzwQd/s42FUcZwdap1ggHlcm6Fb6yjK23joxxARZp2RAvBj7PVlU/UJRP0JTX4EJyj/U5dK5MweXBRs5RVlU1ikZEHO2CBAFaYi6LTpkj1Fy9x2c5hT1I3RYiOe3FKkxzhVlUVmnDUK0UW2L4J90HiFunwzRbI9wqqyvch9l+kG/O8Tx47EurBoF2ldnBMtdNMm6Y9Tl9beHOL8aw/F36xddtw0Jqi7akWXNa9G/J2UuxFQfpTuv3JV/Lq+OlASu69fq1mmjIzGTH6HrTHDTEBAbI7TEdjXvBTKcFyHmrr1A1Qt9ZI8c1IlCw0Fa1hVBvREjttSgbcBpCJAF0Q/a8duREBzxuZ9HA1Td35onKO7PQszRDWCL7bMestbIBe7ejDs9FN32x+IzPaRTQf2av6bw1mntELcPB8gfTv8FcUG6rc4CnK+n9iJEN42NJ2j+FSNOuOk2Tmltrq4/r3pz6GzZI6gTr83072ndmZG49YADy0G5Js5150HCrTPtx5N0qu+XV76KdVo7RArC/PyXyTsozo1AVb1FayA6Y5T9OdIzLTCih5ivuYfRqg1xaYmpgHaCICr75ZWvYp1igajyqhBn0ta+jfK9iN09lS8LcTGdlmbSaQ8liqM9Qv5bB9mCP7f6EPN/aEdkF6uHTGC/5LUsb53WDjFLFyOCcyDmiqzWNopiwdC66inbmHoRIi02iu4o8CVSmJeml4VIqc8LPEm1sMmINuTCxxEpskUbPkRapLW8U6B6GtQvv98rWKf1L2xSPVgVb14IdGWArFgQKNsI41QXe7sP6rJn/BSUqnzWD0jvLPGkwwv6NW+d1g8xAV4OYnzWiSEK01cESuv01UBV/lqsE0NMkHViiAmyTgwxQdaJISbIOoWCyM+dxutInjvlJ8Dj94ufAOd3MeJ1JO9ikPitqM1axphiHslbUVJ0N9Cwpvw8f1J2tKYYU6yDRqDUUhBZr1MM0QAxRAPEEA0QQzRADNEAMUQDxBANEEM0QAzRADHExAv4D6xnrfhK9B6dAAAAAElFTkSuQmCC
" height="39" width="82" />acima.
          </li>
          <li>Cada área responsável pela informação é responsável por:</li>
        </ol>
        <p>
          <strong> &emsp; &emsp;Step 1:</strong> Preencher as informações
          solicitadas corretamente, conforme instruções detalhadas no próprio
          template
        </p>
        <p>
          <strong>&emsp; &emsp;Step 2: </strong>Conciliar as informações
          financeiras de perdas, provisões e recuperações com os respectivos
          saldos contábeis das contas SAP, que serão informados no template.
        </p>
        <p>
          <strong>&emsp; &emsp;Step 3:</strong> Após finalizado o preenchimento
          do template, a planilha deve ser submetida por essa plataforma,
          clicando no botão <span style="color: red">“SUBMETER” </span>e<span style="color: red">
          </span>carregando a planilha.
        </p>
        <p>
          Aguarde a mensagem de
          <span style="color: rgb(0, 32, 96)">“ENVIO CONCLUÍDO COM SUCESSO”.</span>
        </p>
        <p>
          <span style="color: red">IMPORTANTE:</span> É responsabilidade da área
          que as informações enviadas estejam conciliadas com o saldo contábil,
          bem com que mantenham controle e evidências das movimentações versus
          conciliações com o contábil.
        </p>
        <p>
          Qualquer problema ou inconsistência deve ser antecipadamente
          comunicado a equipe de risco operacional.
        </p>
        <p>
          Em caso de questionamento do BANCO CENTRAL sobre eventuais
          inconsistências, a área também será responsável por
          responder/justificar tais situações.
        </p>
        <ol>
          <li>
            Na sequência&nbsp;planilha será analisado pela Equipe de Risco
            Operacional que terá 5 dias úteis para analisar as informações e
            solicitar correções e ou ainda evidências adicionais de conciliações
            com o contábil.
          </li>
          <li>
            <span style="background-color: yellow">Após geração do XML para submissão do DRO no site do BACEN, será
              enviado um quadro resumo para todas cada áreas aprovar os dados
              finais consolidados a serem enviados.</span>
          </li>
        </ol>
        <p>
          <span style="background-color: yellow">O prazo de resposta para aprovação será de 1 dia útil. Caso não
            tenhamos retorno, entenderemos que a informação está aprovada pela
            área de origem.</span>
        </p>
      </div>
      <div v-else>
        <div class="card" v-if="!hasErrors">
          <h6>A sua planilha cobre os seguintes meses:</h6>
          <div class="flex flex-wrap gap-3">
            <Tag style="backgroundColor: grey" class="mr-2" v-for="mes in responseData.coverage" :key="mes" :value="mes">
            </Tag>
          </div>
        </div>
        <div v-if="!hasErrors && !hasWarnings">
          <Message class="customMessage" severity="success" :closable="false">
            <b>A planilha pode ser enviada!</b>
          </Message>
        </div>
        <div v-else-if="!hasErrors">
          <Message class="customMessage" severity="info" :closable="false">
            <b style="margin-top:7px">A planilha pode ser enviada, mas apresenta possíveis erros!</b>
          </Message>
        </div>
        <div v-else>
          <Message class="customMessage" severity="info" :closable="false">
            <b>A planilha não pode ser enviada, pois apresenta erros!</b>
          </Message>
        </div>
        <div v-if="hasErrors">
          <Message class="customMessage" severity="error" :closable="false" v-for="error in responseData.errors"
            :key="error">
            <h6><b>{{ error.message }}</b></h6>
            <div class="flex flex-wrap gap-3">
              <Tag style="color: black" severity="danger" class="mr-2" :value="'Linha: ' + error.line"></Tag>
              <Tag style="color: black" severity="danger" class="mr-2" :value="'Coluna: ' + error.column"></Tag>
              <Tag style="color: black" severity="danger" class="mr-2" :value="'Valor: ' + error.originalValue"></Tag>
            </div>
          </Message>
        </div>
        <hr>
        <div v-if="hasWarnings">
          <Message class="customMessage" severity="warn" v-for="warning in responseData.warnings" :key="warning">
            <h6><b>{{ warning.message }}</b></h6>
            <div class="flex flex-wrap gap-3">
              <Tag style="color: black" severity="warning" class="mr-2" :value="'Linha: ' + warning.line"></Tag>
              <Tag style="color: black" severity="warning" class="mr-2" :value="'Coluna: ' + warning.column"></Tag>
              <Tag style="color: black" severity="warning" class="mr-2" :value="'Valor: ' + warning.originalValue"></Tag>
            </div>
          </Message>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Util from "../service/Util";
import ContentService from "../service/ContentService";
import DROService from "../service/DROService";


export default {
  data() {
    return {
      responseData: [],
      file: null,
      hasNotSended: true,
      hasErrors: false,
      hasWarnings: false,
      validationExec: false,
      responseMsg: {
        title: 'titulo',
        message: 'mensagem'
      },
      dialogResponseMsg: false,
      content: ContentService.contentDROFile[ContentService.currentLang],
    };
  },
  watch: {},
  methods: {
    onUpload() {
      this.$toast.add({
        severity: "info",
        summary: "Success",
        detail: "File Uploaded",
        life: 3000,
      });
    },
    parseValidateResponse(response) {
      console.log(response)
      if (response.success == false) {
        console.log('error')
        return
      }

      this.hasNotSended = false;

      if (response.data.errors.length != 0) {
        this.hasErrors = true;
      }

      if (response.data.warnings.length != 0) {
        this.hasWarnings = true;
      }

      this.responseData = response.data
    },
    removeAttachment() {
      delete this.attachments;
      this.hasNotSended = true
      this.hasErrors = false
      this.hasWarnings = false
      this.dialogResponseMsg = false
    },
    myUploader(event) {
      Util.loading = true;
      this.file = event.files[0];

      var formdata = new FormData();
      formdata.append("content", this.file);

      DROService.fileValidate(formdata).then((responseJson) => {
        Util.loading = false;
        this.parseValidateResponse(responseJson)
      }
      )

    },
    toastResponse(response) {
      this.dialogResponseMsg = true

      if(response.success){
        this.responseMsg = {
          title: 'Sucesso',
          message: 'Arquivo enviado com sucesso!'
        }
      }
      else{
        this.responseMsg = {
          title: 'Erro',
          message: 'Erro ao enviar o arquivo!'
        }
      }
    },
    sendToDatabase() {
      Util.loading = true;

      var formdata = new FormData();
      formdata.append("content", this.file);
      formdata.append("coverage", JSON.stringify(this.responseData.coverage));

      DROService.fileSend(formdata).then((responseJson) => {
        Util.loading = false;
        this.toastResponse(responseJson)
      }
      )

    },
  },
};
</script>

<style>
.customMessage .p-message-text {
  margin-left: 10px
}
</style>